<template>
    <div class="jstd">
        <myhead :param="param" />
        <section>
            <div class="bannerjstd">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">技术团队</div>

            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" style="">
                <div class="hcell" style="width: 130px;text-align: left;display: flex;">
                    <div><span class="btLine">辉腾</span>架构</div>
                </div>
                <div class="hcell gsjgt" style="width: 1400px;background-color: #eeeeee;">

                </div>
                <div class="hcell" style="width: 130px;text-align: right;display: flex;flex-direction: row-reverse">

                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable" style="">
                <div class="hcell" style="width: 130px;text-align: left;display: flex;">
                    <div><span class="btLine">董事</span>长</div>
                </div>
                <div class="hcell " style="width: 600px;">
                    <img :src="dsz" style="display: flex">
                </div>
                <div class="hcell " style="width:800px;padding-left:70px;background-color: #eeeeee;">
                    <div v-if="showdsz">
                        <div class="htable" style="justify-content: right;margin-top: 40px;">
                            <div class="hcell dsztitlediv"><span class="dsztitlec">董事长介绍</span></div>
                            <div class="hcell dsztitlediv"><span class="dsztitle" @click="showdsz=!showdsz"   >董事长致辞</span></div>
                        </div>
                        <div style="font-size:26px;font-weight: bold;color: #202020;text-align: left;">
                            沈正华 先生
                        </div>
                        <div class="htable" style="text-align: left;margin-top: 30px;font-size:14px;line-height:28px;color: rgba(32,32,32,0.8) ">
                            <div class="hcell" style="width: 220px">
                                九龙坡区政协委员<br/>
                                二郎商会常务副会长<br/>
                                九龙坡区工商联执委
                            </div>
                            <div class="hcell" style="width: 240px">
                                重庆市科技创业领军人才<br/>
                                九龙坡区十佳科技领军人才<br/>
                                九龙坡区突出贡献专家
                            </div>
                            <div class="hcell" style="width: 270px">
                                九龙坡区优秀民营企业家<br/>
                                九龙坡区九龙好人<br/>
                                重庆市十大青年渝商年度新锐奖
                            </div>

                        </div>
                        <div class="htable" style="height: 110px;margin-top: 30px;">
                            <div class="hcell"  style="width: 156px;height: 110px;overflow: hidden;margin-right: 5px;" v-for="(n,i) of ryts.length" :key="i" >
                                <img :src="ryts[i]" style="height: 110px;">
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <div class="htable" style="justify-content: right;margin-top: 40px;">
                            <div class="hcell dsztitlediv"><span class="dsztitle" @click="showdsz=!showdsz">董事长介绍</span></div>
                            <div class="hcell dsztitlediv"><span class="dsztitlec" >董事长致辞</span></div>
                        </div>
                        <div style="margin-top: 42px;">
                            <div style="text-align: left;font-size: 14px;line-height: 24px;color: rgba(32,32,32,0.9);margin-right: 35px;">
                                岁月铸品质、时势造翘楚，辉腾能源作为低日照地区最具影响力的太阳能光伏制造商谨向一贯支持和关心我们的社会各界朋友表示最衷心的感谢和最诚挚的敬意！<br/>
                                抚今追昔，我们感慨万千，心潮澎湃。经过10年的艰苦奋斗和顽强拼搏，辉腾能源抓住了机遇，取得了发展，创造了辉煌，争得了地位，赢得了主动。
                                辉腾能源在10年的创业发展历程中逐步培育并形成了“拼搏进取、求实创新、争先创优、勇争一流”的企业精神。正是这种精神造就了辉腾能源的昨天，
                                支撑着辉腾能源的今天，激励着辉腾能源奔向更加辉煌的明天。<br/>
                                展望未来，我们豪情万丈，信心满怀，我们将一招不让往上拼，一步不落往前奔，只争朝夕的抢抓机遇，一如既往地加快发展，全力以赴做大做强，
                                让辉腾能源这艘旗舰乘风破浪，勇往直前！
                            </div>
                        </div>
                        <div class="htable" style="align-items: center;flex-direction: row-reverse;margin-right: 40px;">
                            <div class="hcell szhqm" style=""></div>
                            <div class="hcell" style="font-size: 16px;color: rgba(32,32,32,0.9);height: 20px;">董事长：{{space1}} </div>
                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 130px;text-align: right;display: flex;flex-direction: row-reverse">

                </div>
            </div>
        </section>

        <section style="margin-top: 90px;">
            <div class="body1660 htable" style="">
                <div class="hcell" style="width: 130px;text-align: left;display: flex;">
                    <div><span class="btLine">辉腾</span>团队</div>
                </div>
                <div class="hcell " style="width: 1400px">
                    <div class="htable" style="justify-content: space-between;margin-bottom: 74px;"  v-for="(n,i) of Math.ceil((rows.length)/3)" :key="'item'+rows[3*i].tb_id" >
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-if="rows[3*i]">
                            <div style="height: 340px;background-color:white;overflow: hidden;"><img :src="rows[3*i].tb_image" style="height: 340px;"> </div>
                            <div style="margin-left:30px;margin-top: 30px;text-align: left;">
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);">
                                    <span>{{rows[3*i].tb_title_sub}}</span>
                                </div>
                                <div style="font-size: 24px;font-weight:bold;color: rgba(32,32,32,1);margin-top: 15px;">
                                    <span>{{rows[3*i].tb_title}}</span>
                                </div>
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);margin-top: 15px;">
                                    <span>{{rows[3*i].tb_summary}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-else></div>
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-if="rows[3*i+1]">
                            <div style="height: 340px;background-color:white;overflow: hidden;"><img :src="rows[3*i+1].tb_image" style="height: 340px;"> </div>
                            <div style="margin-left:30px;margin-top: 30px;text-align: left;">
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);">
                                    <span>{{rows[3*i+1].tb_title_sub}}</span>
                                </div>
                                <div style="font-size: 24px;font-weight:bold;color: rgba(32,32,32,1);margin-top: 15px;">
                                    <span>{{rows[3*i+1].tb_title}}</span>
                                </div>
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);margin-top: 15px;">
                                    <span>{{rows[3*i+1].tb_summary}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-else></div>
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-if="rows[3*i+2]">
                            <div style="height: 340px;background-color:white;overflow: hidden;"><img :src="rows[3*i+2].tb_image" style="height: 340px;"> </div>
                            <div style="margin-left:30px;margin-top: 30px;text-align: left;">
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);">
                                    <span>{{rows[3*i+2].tb_title_sub}}</span>
                                </div>
                                <div style="font-size: 24px;font-weight:bold;color: rgba(32,32,32,1);margin-top: 15px;">
                                    <span>{{rows[3*i+2].tb_title}}</span>
                                </div>
                                <div style="font-size: 16px;color: rgba(32,32,32,0.8);margin-top: 15px;">
                                    <span>{{rows[3*i+2].tb_summary}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="hcell" style="width:444px;height: 540px;background-color: #eeeeee;"  v-else></div>

                    </div>

                </div>
                <div class="hcell" style="width: 130px;text-align: right;display: flex;flex-direction: row-reverse">

                </div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBase } from "../hts/api.js"
    const param = {
        pageindex: 5,
        rows: 6,
        page: 1,
    }
    export default {
        name: "",

        components: {
            myhead,
        },
        data() {
            return {
                space1: "\xa0",
                showdsz:true ,
                param,
                dsz: require('../assets/dsz.png'),
                ryts:[
                     require('../assets/ryt1.png'),
                     require('../assets/ryt2.png'),
                     require('../assets/ryt3.png'),
                ],
                rows:[]
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData () {
                listTopicBase({fbId: '56', page: this.page,rows:6 })
                    .then(resData => {
                        this.rows=resData.data.rows
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>

    .bannerjstd{
        background-image: url('~assets/jstd.png');
        height: 600px;
    }
    .szhqm{
        background-image: url('~assets/szh.png');
        width: 114px;
        height: 65px;
        background-repeat: no-repeat;
        background-position: 0px -8px;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;

    }
    .gsjgt{
        background-image: url('~assets/gsjg.png');
        height: 576px;
        background-repeat: no-repeat;
        background-position: 40px 50px;
    }

    .dsztitlediv{
        font-size: 16px;
        width: 125px;
        text-align: left;
    }
    .dsztitle{
        color: #000000;
        cursor:pointer;
    }
    .dsztitle:hover{
        background-color: #c9c9c9;
    }
    .dsztitlec{
        font-weight: bold;
        color: #d7000f;

    }
</style>
